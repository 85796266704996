import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = ({ children, location: { pathname } }) => {
  useEffect(() => {
    if (window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_BACK_FORWARD) {
      //
      //  TODO: fix this, it's not working at the moment as we never trigger "navigation".
      //
      //  We used the back/forward button, so we don't want to change the window's scroll value.
    } else{
      //
      //  We navigated via a link, so reset the view to the top of the page.
      window.scrollTo(0, 0);
    }

    //
    //  Set the document title.return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    var human_name = pathname.replace("/", "");
    var static_name = "Gun Lodge Hotel";
    var breaker = " | ";
    human_name = human_name.charAt(0).toUpperCase() + human_name.substr(1).toLowerCase();
    if(human_name.length === 0){
      document.title = "Home" + breaker + static_name;
    }else{
        document.title = human_name + breaker + static_name;
    }
  }, [pathname]);

  return children || null;
};

export default withRouter(ScrollToTop);
