import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const background_image_path = `url(` + process.env.PUBLIC_URL + `/img/banner-index.jpg) center center`;

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "3em 10% 0 10%",
    maxWidth: "1366px",
    margin: "0 auto 2em auto",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  uppercase_heading: {
    textTransform: "uppercase",
    borderBottom: "1px solid #cccccc",
    margin: "1em 0 1em 0",
  },
  description_text: {
    marginBottom: "1em",
  },
  full_width: {
    width: "100%",
    maxWidth: "475px",
  },
  outer_container: {
    width: "100%",
    maxWidth: "100%",
    height: "100%"
  },
  image_header: {
    margin: 0,
    height: "100vh",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    background: background_image_path,
  },
  anchor: {
    textDecoration: "none",
    color: "#888888",
    fontWeight: "700",
  },
  home_background: {
    background: "rgba(0,0,0,0.8)",
    width: "100%",
    textAlign: "center",
    color: "#ffffff",
    padding: "3em 1em 3em 1em",
  },
  flex_center: {
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
  },
  heading_line_one: {
    textTransform: "uppercase",
    fontSize: "2.75em",
    margin: 0,
  },
  heading_line_two: {
    textTransform: "uppercase",
    fontSize: "2.05em",
    margin: 0,
  },
  heading_line_three: {
    textTransform: "uppercase",
    fontSize: "1.5em",
    margin: "0.5em",
  },
}));


export default function Thanks() {
  const classes = useStyles();

  return (
    <div className={classes.outer_container}>
      <div className={classes.image_header} key="xyz">
        <div className={classes.home_background}>
          <h1 className={classes.heading_line_one}>The Gun Lodge Hotel</h1>
          <h3 className={classes.heading_line_two}>& Cromal Hill Restaurant</h3>
          <hr/>
          <h4 className={classes.heading_line_three}>Experience Scotland's History</h4>
          <p>The Hotel & Restaurant are now closed, we would like to thank all of of our patrons and supporters for their service over the years.</p>
        </div>
      </div>
    </div>
  );
}
